    .hero-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        background-color: #e3f1fa;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }
    
    .Hero-title {
        font-size: 80px;
        margin: 0;
        font-weight: lighter;
    }
    
    .hero-p {
        font-size: 24px;
        width: 480px;
    }
    
    .dot {
        color: #6cbcc7;
        font-size: 80px;
    }
    
    a {
        text-decoration: none;
    }
    
    .bibi-linkedin {
        transition: 1s;
        fill: #0f1b61;
        ;
    }
    
    .bibi-linkedin:hover {
        fill: #6cbcc7;
    }
    
    .bibi-github {
        transition: 1s;
        fill: #0f1b61;
    }
    
    .bibi-github:hover {
        fill: #6cbcc7;
    }
    
    .scroll {
        margin-top: 20px;
        color: rgba(128, 128, 128, 0.623);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        font-size: 14px;
    }
    
    span {
        -webkit-animation: wave-text 3s ease-in-out infinite;
        animation: wave-text 3s ease-in-out infinite;
    }
    
    .scrollspan:nth-of-type(1) {
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
    }
    
    .scroll span:nth-of-type(2) {
        -webkit-animation-delay: 3.2s;
        animation-delay: 3.2s;
    }
    
    .scroll span:nth-of-type(3) {
        -webkit-animation-delay: 3.3s;
        animation-delay: 3.3s;
    }
    
    .scroll span:nth-of-type(4) {
        -webkit-animation-delay: 3.4s;
        animation-delay: 3.4s;
    }
    
    .scroll span:nth-of-type(5) {
        -webkit-animation-delay: 3.6s;
        animation-delay: 3.6s;
    }
    
    @-webkit-keyframes wave-text {
        00% {
            transform: translateY(0em);
        }
        60% {
            transform: translateY(-0.6em);
        }
        100% {
            transform: translateY(0em);
        }
    }
    
    @keyframes wave-text {
        00% {
            transform: translateY(0em);
        }
        60% {
            transform: translateY(-0.6em);
        }
        100% {
            transform: translateY(0em);
        }
    }
    
    @media only screen and (max-width: 540px) {
        .hero-p {
            font-size: 24px;
            width: 300px;
        }
        .Hero-title {
            font-size: 60px;
        }
    }
    
    @media only screen and (max-width: 316px) {
        .hero-p {
            font-size: 24px;
            width: 200px;
        }
        .Hero-title {
            font-size: 60px;
        }
    }