    .contact-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        background-color: #e3f1fa;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    
    .contactr {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        background-color: #e3f1fa;
    }
    
    .pre-form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        background-color: #e3f1fa;
    }
    
    .contact-form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        background-color: #e3f1fa;
    }
    
    .form-control {
        height: 40px;
        width: 350px;
        border-radius: 5px;
        border: 1px #6cbcc7 solid;
        outline-width: 0;
        ;
    }
    
    .contact-titel {
        font-size: 80px;
        margin: 0;
        font-weight: lighter;
    }
    
    .contact-p {
        font-size: 24px;
        width: 480px;
    }
    
    button {
        width: 60px;
        height: 30px;
        border: #6cbcc7 1px solid;
        background-color: white;
        color: #6cbcc7;
        border-radius: 7px;
        transition: 0.5s;
    }
    
    button:hover {
        cursor: pointer;
        background-color: #6cbcc7;
        color: white;
        transform: scale(1.2);
        border: white 1px solid;
    }
    
    @media only screen and (max-width: 420px) {
        .form-control {
            width: 220px;
        }
    }
    
    @media only screen and (max-width: 360px) {
        .form-control {
            width: 220px;
        }
        .contact-titel {
            font-size: 60px;
        }
    }
    
    @media only screen and (max-width: 290px) {
        .contact-titel {
            font-size: 50px;
        }
        .form-control {
            width: 190px;
        }
        img {
            width: 150px;
        }
    }