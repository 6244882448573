.projects-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    background-color: #e3f1fa;
}

.projects-titel {
    font-size: 80px;
    margin: 0;
}

.projects-p {
    font-size: 24px;
    width: 300px;
    margin: 0;
}

.projects-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}

.card-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    padding-left: 75px;
    width: 500px;
}

hr {
    width: 200px;
    border: #6cbcc7 1px solid;
}

.card-p {
    font-family: 'Inconsolata', monospace;
    font-weight: bolder;
}

.herohr {
    height: 350px;
    width: 0;
}

.herohr2 {
    height: 150px;
    width: 0;
    margin: 0;
}

img {
    border: #6cbcc7 1px solid;
    border-radius: 3px;
}

@media only screen and (max-width: 907px) {
    .card-info {
        padding: 2px;
        width: 400px;
    }
}

@media only screen and (max-width: 510px) {
    .card-p {
        width: 250px;
    }
}

@media only screen and (max-width: 420px) {
    .card-info {
        padding: 2px;
        width: 250px;
    }
}

@media only screen and (max-width: 420px) {
    .card-info {
        padding: 0px;
        width: 220px;
    }
    img {
        width: 222px;
    }
}

@media only screen and (max-width: 340px) {
    img {
        width: 170px;
    }
    .projects-p {
        width: 190px;
    }
    .card-p {
        width: 190px;
    }
    .projects-titel {
        font-size: 50px;
    }
}